.Place-Container {
  margin-top: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Place-Container h1 {
  color: #8d511c;
  margin-bottom: 3rem;
  text-align: center;
}

.Place-Text-Container {
  margin-bottom: 5rem;
  margin-left: 5vw;
}

.Place-Text-Times {
  display: inline-block;
  margin-left: 5rem;
}

.Schedule-Container {
  display: flex;
  justify-content: center;
}

.Schedule-Days {
  font-family: proxima nova;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 50px;
}

.Schedule-Times {
  font-family: proxima nova;
  font-size: 1.2rem;
  margin-left: 5rem;
  line-height: 50px;
}

.Place-Image {
  height: 30rem;
  width: 30rem;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  margin-bottom: 0.5rem;
}

.Place-Image-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Place-Image-Container p {
  text-align: center;
  font-size: 1rem;
  opacity: 0.7;
}

.Place-Text-Container-Mobile {
  display: flex;
  width: 30rem;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.Schedule-Container-Mobile {
  text-align: center;
  flex-basis: 50%;
  margin-top: 1rem;
}

.Place-Text-Container-Mobile hr {
  margin: 0 auto;
  width: 10rem;
  height: 1px;
  border: none;
  background-color: #8d511c;
  margin-top: 1rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Place-Text-Container {
    display: none;
  }

  .Place-Container {
    flex-direction: column;
  }

  .Place-Image-Container p {
    text-align: left;
    margin-right: auto;
    margin-left: 0.5rem;
    line-height: 20px;
  }

  .Place-Container h1 {
    margin-bottom: 7rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Place-Text-Container {
    display: none;
  }

  .Place-Container {
    flex-direction: column;
  }

  .Place-Image-Container p {
    text-align: left;
    margin-right: auto;
    margin-left: 0.5rem;
    line-height: 20px;
  }

  .Place-Container h1 {
    margin-bottom: 7rem;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Place-Text-Container {
    display: none;
  }

  .Place-Container {
    flex-direction: column;
  }

  .Place-Image-Container p {
    text-align: left;
    margin-right: auto;
    margin-left: 0.5rem;
  }

  .Place-Container h1 {
    margin-bottom: 7rem;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Place-Text-Container {
    display: block;
  }

  .Place-Container {
    flex-direction: row;
  }

  .Place-Title-Mobile {
    display: none;
  }

  .Place-Image-Container p {
    text-align: center;
    margin-right: 0;
  }

  .Place-Text-Container-Mobile {
    display: none;
  }

  .Place-Container h1 {
    margin-bottom: 3rem;
  }
}
