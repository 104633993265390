::selection {
  background-color: #8d511c;
  color: #eee7dc;
}

* {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  line-height: 30px;
}

h1 {
  font-family: adelle;
  font-size: 3rem;
  line-height: 70px;
}

h2,
li {
  font-family: adelle;
  font-size: 1.5rem;
  font-weight: bold;
}

p,
a,
button,
li {
  font-family: proxima nova;
  font-size: 1.2rem;
}

body {
  background-color: #eee7dc;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  html {
    font-size: 8px;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 40px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.3rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 2rem;
    font-weight: bold;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  html {
    font-size: 10px;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 40px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.3rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 2rem;
    font-weight: bold;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }

  h1 {
    font-size: 3rem;
    line-height: 70px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.2rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  html {
    font-size: 16px;
  }

  h1 {
    font-size: 3rem;
    line-height: 70px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.2rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

nav {
  position: fixed;
  z-index: 99;
}

.Nav-Text {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: 0.5rem;
  cursor: pointer;
  z-index: 99;
}

.Nav-Text a {
  transition: ease-in-out 0.3s;
  margin-left: 5rem;
  text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 10px;
  text-decoration: none;
  color: black;
  font-family: adelle;
  font-size: 1.5rem;
  font-weight: bold;
}

.Nav-Image {
  width: 15rem;
  position: fixed;
  margin: 1.5rem 0 0 3rem;
  z-index: 100;
}

.Nav-Text a:hover {
  transition: ease-in-out 0.2s;
  text-shadow: rgba(0, 0, 0, 0.7) 0px 0px 10px;
  color: #eee7dc;
}

.Drawer {
  background-color: #eee7dc;
  width: 70vw;
  max-width: 30rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 0.5s ease-in-out;
  z-index: 98;
  box-shadow: rgba(0, 0, 0, 0.2) -5px 0px 30px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.Drawer.active {
  right: 0;
  transition: 0.5s ease-in-out;
  z-index: 50;
}

.Drawer-Navigation {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}

.Drawer-Navigation li,
a {
  transition: ease-in-out 0.2s;
  list-style: none;
  padding: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  color: black;
}

.Drawer-Navigation a {
  transition: ease-in-out 0.2s;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: adelle;
  font-size: 2rem;
  font-weight: bold;
}

.Drawer-Navigation a:hover {
  transition: ease-in-out 0.2s;
  text-shadow: rgba(0, 0, 0, 0.7) 0px 0px 10px;
  color: #eee7dc;
}

.Menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 2.5rem;
  top: 1.2rem;
  z-index: 99;
  cursor: pointer;
  padding: 0.5rem;
}

.Hamburger-Menu {
  height: 5px;
  background-color: black;
  width: 40px;
  margin-top: 5px;
}

#Hamburger1 {
  margin: 0;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Nav-Text {
    display: none;
  }

  .Nav-Image {
    width: 20rem;
  }

  .Mobile-Drawer {
    display: block;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Nav-Text {
    display: none;
  }

  .Nav-Image {
    width: 20rem;
  }

  .Mobile-Drawer {
    display: block;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Nav-Text {
    display: none;
  }

  .Nav-Image {
    width: 20rem;
  }

  .Mobile-Drawer {
    display: block;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Nav-Text {
    display: flex;
  }

  .Nav-Image {
    width: 15rem;
  }

  .Mobile-Drawer {
    display: none;
  }
}

.Home-Container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  box-shadow: rgba(0, 0, 0, 0.3) 0px -10px 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.Home-Container h1 {
  font-size: 3rem;
  text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 5px;
}

.Home-Container p {
  margin-top: 3rem;
  text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 5px;
}

.Home-Text-Container {
  width: 40rem;
  padding: 7rem 5rem 7rem 10rem;
  background: transparent linear-gradient(270deg, #ffffff00 70%, #ffffff9e 100%);
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Home-Text-Container {
    background: rgba(255, 255, 255, 0.3);
    padding: 5rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Home-Text-Container {
    background: rgba(255, 255, 255, 0.3);
    width: 100vw;
    padding: 5rem;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Home-Text-Container {
    background: transparent
      linear-gradient(270deg, #ffffff00 70%, #ffffff9e 100%);
    width: 40rem;
    padding: 7rem 5rem 7rem 10rem;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Home-Text-Container {
    background: transparent
      linear-gradient(270deg, #ffffff00 70%, #ffffff9e 100%);
    width: 40rem;
    padding: 7rem 5rem 7rem 10rem;
  }
}

.Story-Container {
  margin-top: 15rem;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Story-Text-Container {
  width: 40rem;
  margin: 0 6rem 0 10rem;
}

.Story-Container p {
  margin-top: 3rem;
}
.Story-Container h1 {
  color: #8d511c;
}

.Story-Image {
  height: 30rem;
  width: 40rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Story-Container {
    flex-direction: column;
  }
  .Story-Text-Container {
    display: none;
  }

  .Story-Image {
    margin: 7rem 0 3rem 0;
    width: 35rem;
  }

  .Story-Text-Mobile {
    padding: 0 5rem;
    text-align: center;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Story-Container {
    flex-direction: column;
  }
  .Story-Text-Container {
    display: none;
  }

  .Story-Image {
    margin: 7rem 0 3rem 0;
    width: 35rem;
  }

  .Story-Text-Mobile {
    padding: 0 5rem;
    text-align: center;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Story-Container {
    flex-direction: column;
  }
  .Story-Text-Container {
    display: none;
  }

  .Story-Image {
    margin: 7rem 0 3rem 0;
  }

  .Story-Text-Mobile {
    padding: 0 5rem;
    text-align: center;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Story-Container {
    flex-direction: row;
  }

  .Story-Text-Container {
    display: block;
  }

  .Story-Text-Mobile {
    display: none;
  }

  .Story-Title-Mobile {
    display: none;
  }

  .Story-Image {
    margin-left: auto;
  }
}

.Baking-Container {
  margin-top: 15rem;
}

.Baking-Container h1 {
  max-width: 50vw;
  text-align: center;
  color: #8d511c;
  margin: 0 auto;
}

.Baking-Slide-Container {
  display: flex;
  justify-content: center;
  margin: 10rem auto 0 auto;
  align-items: center;
  background-color: #e3dacc;
  max-width: 45rem;
  padding: 3rem;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  flex-direction: row;
}

.Baking-Slide-Image {
  width: 25rem;
  height: 17rem;
  background-color: black;
  margin-right: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  background-position: center;
  display: block;
}

.Baking-Slide-Container p {
  max-width: 25rem;
  line-height: 50px;
  font-family: proxima nova;
  font-weight: bold;
  text-align: center;
}

.swiper-pagination {
  position: relative;
  margin-top: 7rem;
  display: block;
}

.swiper-pagination-bullet-active {
  background-color: #8d511c;
}

.swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Baking-Slide-Container {
    max-width: 26rem;
    display: flex;
    flex-direction: column;
    height: 35rem;
  }

  .Baking-Slide-Container p {
    line-height: 30px;
  }

  .swiper-pagination {
    margin-top: 5rem;
  }

  .Baking-Slide-Image {
    display: none;
  }

  .Baking-Container h1 {
    max-width: none;
    margin: 0 2rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Baking-Slide-Container {
    max-width: 26rem;
    display: flex;
    flex-direction: column;
    height: 35rem;
  }

  .Baking-Slide-Container p {
    line-height: 30px;
  }

  .swiper-pagination {
    margin-top: 5rem;
  }

  .Baking-Slide-Image {
    display: none;
  }

  .Baking-Container h1 {
    max-width: none;
    margin: 0 2rem;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Baking-Slide-Container {
    max-width: none;
    width: 30rem;
    margin: 10rem auto 0 auto;
    max-height: 20rem;
  }

  .Baking-Container h1 {
    max-width: 45rem;
  }

  .Baking-Container h1 {
    max-width: 50vw;
    margin: 0 auto;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Baking-Slide-Container {
    max-width: none;
    width: 45rem;
    height: auto;
    margin: 10rem auto 0 auto;
  }
  .Baking-Slide-Container {
    flex-direction: row;
  }

  .swiper-pagination {
    margin-top: 7rem;
  }

  .Baking-Container h1 {
    max-width: 50vw;
  }

  .Baking-Slide-Image {
    display: block;
    margin-right: 2rem;
  }

  .Baking-Container h1 {
    max-width: 50vw;
    margin: 0 auto;
  }
}

.Ebay-Container {
  background-color: #e3dacc;
  margin-top: 15rem;
  padding: 10rem;
  overflow: hidden;
}

.Ebay-Container h1 {
  color: #8d511c;
  position: relative;
  z-index: 2;
}

.Ebay-Container a {
  background-color: transparent;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-weight: bold;
  border: solid 3px black;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: ease-in-out 0.3s;
  margin-top: 2rem;
  color: black;
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 2;
}

.Ebay-Container a:hover {
  background-color: black;
  color: #e3dacc;
  transition: ease-in-out 0.2s;
}

.Ebay-Container img {
  position: absolute;
  margin-top: -20rem;
  right: 0px;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Ebay-Container img {
    margin-top: -15rem;
  }

  .Ebay-Container a {
    padding: 0 2rem;
  }

  .Ebay-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Ebay-Container h1 {
    text-align: center;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Ebay-Container img {
    margin-top: -15rem;
  }

  .Ebay-Container a {
    padding: 0 2rem;
  }

  .Ebay-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Ebay-Container h1 {
    text-align: center;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Ebay-Container a {
    padding: 0.5rem 2rem;
  }

  .Ebay-Container {
    display: block;
  }

  .Ebay-Container h1 {
    text-align: left;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Ebay-Container img {
    margin-top: -20rem;
  }

  .Ebay-Container a {
    padding: 0.5rem 2rem;
  }

  .Ebay-Container {
    display: block;
  }

  .Ebay-Container h1 {
    text-align: left;
  }
}

.Place-Container {
  margin-top: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Place-Container h1 {
  color: #8d511c;
  margin-bottom: 3rem;
  text-align: center;
}

.Place-Text-Container {
  margin-bottom: 5rem;
  margin-left: 5vw;
}

.Place-Text-Times {
  display: inline-block;
  margin-left: 5rem;
}

.Schedule-Container {
  display: flex;
  justify-content: center;
}

.Schedule-Days {
  font-family: proxima nova;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 50px;
}

.Schedule-Times {
  font-family: proxima nova;
  font-size: 1.2rem;
  margin-left: 5rem;
  line-height: 50px;
}

.Place-Image {
  height: 30rem;
  width: 30rem;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  margin-bottom: 0.5rem;
}

.Place-Image-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Place-Image-Container p {
  text-align: center;
  font-size: 1rem;
  opacity: 0.7;
}

.Place-Text-Container-Mobile {
  display: flex;
  width: 30rem;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.Schedule-Container-Mobile {
  text-align: center;
  flex-basis: 50%;
  margin-top: 1rem;
}

.Place-Text-Container-Mobile hr {
  margin: 0 auto;
  width: 10rem;
  height: 1px;
  border: none;
  background-color: #8d511c;
  margin-top: 1rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Place-Text-Container {
    display: none;
  }

  .Place-Container {
    flex-direction: column;
  }

  .Place-Image-Container p {
    text-align: left;
    margin-right: auto;
    margin-left: 0.5rem;
    line-height: 20px;
  }

  .Place-Container h1 {
    margin-bottom: 7rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Place-Text-Container {
    display: none;
  }

  .Place-Container {
    flex-direction: column;
  }

  .Place-Image-Container p {
    text-align: left;
    margin-right: auto;
    margin-left: 0.5rem;
    line-height: 20px;
  }

  .Place-Container h1 {
    margin-bottom: 7rem;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Place-Text-Container {
    display: none;
  }

  .Place-Container {
    flex-direction: column;
  }

  .Place-Image-Container p {
    text-align: left;
    margin-right: auto;
    margin-left: 0.5rem;
  }

  .Place-Container h1 {
    margin-bottom: 7rem;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Place-Text-Container {
    display: block;
  }

  .Place-Container {
    flex-direction: row;
  }

  .Place-Title-Mobile {
    display: none;
  }

  .Place-Image-Container p {
    text-align: center;
    margin-right: 0;
  }

  .Place-Text-Container-Mobile {
    display: none;
  }

  .Place-Container h1 {
    margin-bottom: 3rem;
  }
}

.Contact-Container {
  margin-top: 15rem;
  background-color: #e3dacc;
  display: flex;
  align-items: center;
  padding: 5rem 0 12rem 0;
  flex-direction: column;
}

.Contact-Container h1 {
  color: #8d511c;
  margin-bottom: 5rem;
}

.Contact-Container img {
  height: 4rem;
  -webkit-filter: invert(31%) sepia(67%) saturate(522%) hue-rotate(347deg)
    brightness(96%) contrast(96%);
          filter: invert(31%) sepia(67%) saturate(522%) hue-rotate(347deg)
    brightness(96%) contrast(96%);
}

.Icon-Container {
  display: flex;
  align-items: center;
  margin-top: 4rem;
}

.Icon-Container a {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  margin-left: 5rem;
}

footer {
  padding: 2rem;
  background-color: #8d511c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

footer h2 {
  color: white;
  text-align: center;
}
footer p {
  color: white;
  text-align: center;
  opacity: 0.7;
}

footer a {
  color: white;
}

.Policy-Container {
  position: absolute;
  left: 2rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Policy-Container {
    position: static;
  }
  footer hr {
    border: none;
    height: 2px;
    background-color: #e3dacc;
    margin: 1.5rem auto;
    width: 5rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Policy-Container {
    position: static;
  }
  footer hr {
    border: none;
    height: 2px;
    background-color: #e3dacc;
    margin: 1.5rem auto;
    width: 5rem;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Policy-Container {
    position: static;
  }
  footer hr {
    border: none;
    height: 2px;
    background-color: #e3dacc;
    margin: 1.5rem auto;
    width: 5rem;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Policy-Container {
    position: absolute;
    left: 2rem;
  }

  footer hr {
    display: none;
  }
}

.Imprint-Container {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  padding: 6rem 10rem;
}

.Imprint-Container h1 {
  font-size: 2rem;
  text-decoration: underline;
  margin-top: 5rem;
}

.Imprint-Container p {
  font-size: 1rem;
}

.Imprint-Container a {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: blue;
}

.Imprint-Container span {
  display: block;
  margin-top: 1rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Imprint-Container {
    padding: 6rem 3rem;
  }

  .Imprint-Container p {
    font-size: 1.3rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Imprint-Container {
    padding: 6rem 5rem;
  }

  .Imprint-Container p {
    font-size: 1.3rem;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Imprint-Container {
    padding: 6rem 7rem;
  }

  .Imprint-Container p {
    font-size: 1rem;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Imprint-Container {
    padding: 6rem 10rem;
  }

  .Imprint-Container p {
    font-size: 1rem;
  }
}

