.Home-Container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  box-shadow: rgba(0, 0, 0, 0.3) 0px -10px 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.Home-Container h1 {
  font-size: 3rem;
  text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 5px;
}

.Home-Container p {
  margin-top: 3rem;
  text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 5px;
}

.Home-Text-Container {
  width: 40rem;
  padding: 7rem 5rem 7rem 10rem;
  background: transparent linear-gradient(270deg, #ffffff00 70%, #ffffff9e 100%);
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Home-Text-Container {
    background: rgba(255, 255, 255, 0.3);
    padding: 5rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Home-Text-Container {
    background: rgba(255, 255, 255, 0.3);
    width: 100vw;
    padding: 5rem;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Home-Text-Container {
    background: transparent
      linear-gradient(270deg, #ffffff00 70%, #ffffff9e 100%);
    width: 40rem;
    padding: 7rem 5rem 7rem 10rem;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Home-Text-Container {
    background: transparent
      linear-gradient(270deg, #ffffff00 70%, #ffffff9e 100%);
    width: 40rem;
    padding: 7rem 5rem 7rem 10rem;
  }
}
