.Ebay-Container {
  background-color: #e3dacc;
  margin-top: 15rem;
  padding: 10rem;
  overflow: hidden;
}

.Ebay-Container h1 {
  color: #8d511c;
  position: relative;
  z-index: 2;
}

.Ebay-Container a {
  background-color: transparent;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-weight: bold;
  border: solid 3px black;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: ease-in-out 0.3s;
  margin-top: 2rem;
  color: black;
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 2;
}

.Ebay-Container a:hover {
  background-color: black;
  color: #e3dacc;
  transition: ease-in-out 0.2s;
}

.Ebay-Container img {
  position: absolute;
  margin-top: -20rem;
  right: 0px;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Ebay-Container img {
    margin-top: -15rem;
  }

  .Ebay-Container a {
    padding: 0 2rem;
  }

  .Ebay-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Ebay-Container h1 {
    text-align: center;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Ebay-Container img {
    margin-top: -15rem;
  }

  .Ebay-Container a {
    padding: 0 2rem;
  }

  .Ebay-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Ebay-Container h1 {
    text-align: center;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Ebay-Container a {
    padding: 0.5rem 2rem;
  }

  .Ebay-Container {
    display: block;
  }

  .Ebay-Container h1 {
    text-align: left;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Ebay-Container img {
    margin-top: -20rem;
  }

  .Ebay-Container a {
    padding: 0.5rem 2rem;
  }

  .Ebay-Container {
    display: block;
  }

  .Ebay-Container h1 {
    text-align: left;
  }
}
