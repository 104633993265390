footer {
  padding: 2rem;
  background-color: #8d511c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

footer h2 {
  color: white;
  text-align: center;
}
footer p {
  color: white;
  text-align: center;
  opacity: 0.7;
}

footer a {
  color: white;
}

.Policy-Container {
  position: absolute;
  left: 2rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Policy-Container {
    position: static;
  }
  footer hr {
    border: none;
    height: 2px;
    background-color: #e3dacc;
    margin: 1.5rem auto;
    width: 5rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Policy-Container {
    position: static;
  }
  footer hr {
    border: none;
    height: 2px;
    background-color: #e3dacc;
    margin: 1.5rem auto;
    width: 5rem;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Policy-Container {
    position: static;
  }
  footer hr {
    border: none;
    height: 2px;
    background-color: #e3dacc;
    margin: 1.5rem auto;
    width: 5rem;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Policy-Container {
    position: absolute;
    left: 2rem;
  }

  footer hr {
    display: none;
  }
}
