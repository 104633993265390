::selection {
  background-color: #8d511c;
  color: #eee7dc;
}

* {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  line-height: 30px;
}

h1 {
  font-family: adelle;
  font-size: 3rem;
  line-height: 70px;
}

h2,
li {
  font-family: adelle;
  font-size: 1.5rem;
  font-weight: bold;
}

p,
a,
button,
li {
  font-family: proxima nova;
  font-size: 1.2rem;
}

body {
  background-color: #eee7dc;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  html {
    font-size: 8px;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 40px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.3rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 2rem;
    font-weight: bold;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  html {
    font-size: 10px;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 40px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.3rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 2rem;
    font-weight: bold;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }

  h1 {
    font-size: 3rem;
    line-height: 70px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.2rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  html {
    font-size: 16px;
  }

  h1 {
    font-size: 3rem;
    line-height: 70px;
  }

  p,
  a,
  button {
    font-family: proxima nova;
    font-size: 1.2rem;
  }
  h2,
  li {
    font-family: adelle;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
