.Baking-Container {
  margin-top: 15rem;
}

.Baking-Container h1 {
  max-width: 50vw;
  text-align: center;
  color: #8d511c;
  margin: 0 auto;
}

.Baking-Slide-Container {
  display: flex;
  justify-content: center;
  margin: 10rem auto 0 auto;
  align-items: center;
  background-color: #e3dacc;
  max-width: 45rem;
  padding: 3rem;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  flex-direction: row;
}

.Baking-Slide-Image {
  width: 25rem;
  height: 17rem;
  background-color: black;
  margin-right: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  background-position: center;
  display: block;
}

.Baking-Slide-Container p {
  max-width: 25rem;
  line-height: 50px;
  font-family: proxima nova;
  font-weight: bold;
  text-align: center;
}

.swiper-pagination {
  position: relative;
  margin-top: 7rem;
  display: block;
}

.swiper-pagination-bullet-active {
  background-color: #8d511c;
}

.swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Baking-Slide-Container {
    max-width: 26rem;
    display: flex;
    flex-direction: column;
    height: 35rem;
  }

  .Baking-Slide-Container p {
    line-height: 30px;
  }

  .swiper-pagination {
    margin-top: 5rem;
  }

  .Baking-Slide-Image {
    display: none;
  }

  .Baking-Container h1 {
    max-width: none;
    margin: 0 2rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Baking-Slide-Container {
    max-width: 26rem;
    display: flex;
    flex-direction: column;
    height: 35rem;
  }

  .Baking-Slide-Container p {
    line-height: 30px;
  }

  .swiper-pagination {
    margin-top: 5rem;
  }

  .Baking-Slide-Image {
    display: none;
  }

  .Baking-Container h1 {
    max-width: none;
    margin: 0 2rem;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Baking-Slide-Container {
    max-width: none;
    width: 30rem;
    margin: 10rem auto 0 auto;
    max-height: 20rem;
  }

  .Baking-Container h1 {
    max-width: 45rem;
  }

  .Baking-Container h1 {
    max-width: 50vw;
    margin: 0 auto;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Baking-Slide-Container {
    max-width: none;
    width: 45rem;
    height: auto;
    margin: 10rem auto 0 auto;
  }
  .Baking-Slide-Container {
    flex-direction: row;
  }

  .swiper-pagination {
    margin-top: 7rem;
  }

  .Baking-Container h1 {
    max-width: 50vw;
  }

  .Baking-Slide-Image {
    display: block;
    margin-right: 2rem;
  }

  .Baking-Container h1 {
    max-width: 50vw;
    margin: 0 auto;
  }
}
