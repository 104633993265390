nav {
  position: fixed;
  z-index: 99;
}

.Nav-Text {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: 0.5rem;
  cursor: pointer;
  z-index: 99;
}

.Nav-Text a {
  transition: ease-in-out 0.3s;
  margin-left: 5rem;
  text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 10px;
  text-decoration: none;
  color: black;
  font-family: adelle;
  font-size: 1.5rem;
  font-weight: bold;
}

.Nav-Image {
  width: 15rem;
  position: fixed;
  margin: 1.5rem 0 0 3rem;
  z-index: 100;
}

.Nav-Text a:hover {
  transition: ease-in-out 0.2s;
  text-shadow: rgba(0, 0, 0, 0.7) 0px 0px 10px;
  color: #eee7dc;
}

.Drawer {
  background-color: #eee7dc;
  width: 70vw;
  max-width: 30rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 0.5s ease-in-out;
  z-index: 98;
  box-shadow: rgba(0, 0, 0, 0.2) -5px 0px 30px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.Drawer.active {
  right: 0;
  transition: 0.5s ease-in-out;
  z-index: 50;
}

.Drawer-Navigation {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}

.Drawer-Navigation li,
a {
  transition: ease-in-out 0.2s;
  list-style: none;
  padding: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  color: black;
}

.Drawer-Navigation a {
  transition: ease-in-out 0.2s;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: adelle;
  font-size: 2rem;
  font-weight: bold;
}

.Drawer-Navigation a:hover {
  transition: ease-in-out 0.2s;
  text-shadow: rgba(0, 0, 0, 0.7) 0px 0px 10px;
  color: #eee7dc;
}

.Menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 2.5rem;
  top: 1.2rem;
  z-index: 99;
  cursor: pointer;
  padding: 0.5rem;
}

.Hamburger-Menu {
  height: 5px;
  background-color: black;
  width: 40px;
  margin-top: 5px;
}

#Hamburger1 {
  margin: 0;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Nav-Text {
    display: none;
  }

  .Nav-Image {
    width: 20rem;
  }

  .Mobile-Drawer {
    display: block;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Nav-Text {
    display: none;
  }

  .Nav-Image {
    width: 20rem;
  }

  .Mobile-Drawer {
    display: block;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Nav-Text {
    display: none;
  }

  .Nav-Image {
    width: 20rem;
  }

  .Mobile-Drawer {
    display: block;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Nav-Text {
    display: flex;
  }

  .Nav-Image {
    width: 15rem;
  }

  .Mobile-Drawer {
    display: none;
  }
}
