.Contact-Container {
  margin-top: 15rem;
  background-color: #e3dacc;
  display: flex;
  align-items: center;
  padding: 5rem 0 12rem 0;
  flex-direction: column;
}

.Contact-Container h1 {
  color: #8d511c;
  margin-bottom: 5rem;
}

.Contact-Container img {
  height: 4rem;
  filter: invert(31%) sepia(67%) saturate(522%) hue-rotate(347deg)
    brightness(96%) contrast(96%);
}

.Icon-Container {
  display: flex;
  align-items: center;
  margin-top: 4rem;
}

.Icon-Container a {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  margin-left: 5rem;
}
