.Imprint-Container {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  padding: 6rem 10rem;
}

.Imprint-Container h1 {
  font-size: 2rem;
  text-decoration: underline;
  margin-top: 5rem;
}

.Imprint-Container p {
  font-size: 1rem;
}

.Imprint-Container a {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: blue;
}

.Imprint-Container span {
  display: block;
  margin-top: 1rem;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Imprint-Container {
    padding: 6rem 3rem;
  }

  .Imprint-Container p {
    font-size: 1.3rem;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Imprint-Container {
    padding: 6rem 5rem;
  }

  .Imprint-Container p {
    font-size: 1.3rem;
  }
}

/*
Tablet
*/

@media only screen and (min-width: 768px) {
  .Imprint-Container {
    padding: 6rem 7rem;
  }

  .Imprint-Container p {
    font-size: 1rem;
  }
}

/*
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Imprint-Container {
    padding: 6rem 10rem;
  }

  .Imprint-Container p {
    font-size: 1rem;
  }
}
