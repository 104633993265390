.Story-Container {
  margin-top: 15rem;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Story-Text-Container {
  width: 40rem;
  margin: 0 6rem 0 10rem;
}

.Story-Container p {
  margin-top: 3rem;
}
.Story-Container h1 {
  color: #8d511c;
}

.Story-Image {
  height: 30rem;
  width: 40rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*
Small Phone
*/

@media only screen and (max-width: 350px) {
  .Story-Container {
    flex-direction: column;
  }
  .Story-Text-Container {
    display: none;
  }

  .Story-Image {
    margin: 7rem 0 3rem 0;
    width: 35rem;
  }

  .Story-Text-Mobile {
    padding: 0 5rem;
    text-align: center;
  }
}

/*
Phone
*/

@media only screen and (min-width: 351px) {
  .Story-Container {
    flex-direction: column;
  }
  .Story-Text-Container {
    display: none;
  }

  .Story-Image {
    margin: 7rem 0 3rem 0;
    width: 35rem;
  }

  .Story-Text-Mobile {
    padding: 0 5rem;
    text-align: center;
  }
}

/* 
Tablet
*/

@media only screen and (min-width: 768px) {
  .Story-Container {
    flex-direction: column;
  }
  .Story-Text-Container {
    display: none;
  }

  .Story-Image {
    margin: 7rem 0 3rem 0;
  }

  .Story-Text-Mobile {
    padding: 0 5rem;
    text-align: center;
  }
}

/* 
Desktop
*/

@media only screen and (min-width: 1200px) {
  .Story-Container {
    flex-direction: row;
  }

  .Story-Text-Container {
    display: block;
  }

  .Story-Text-Mobile {
    display: none;
  }

  .Story-Title-Mobile {
    display: none;
  }

  .Story-Image {
    margin-left: auto;
  }
}
